import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Android Development? ",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Android software development is a process by which developers develop new applications for devices running the Android operating system. Android apps can be written using Java, Kotlin and CC++ languages alongside the Android software development kit (SDK). The use of other languages is also possible when it comes to Android development"
        }
    }
}
const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Clients Choose Codestaff For Android Development?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Despite Android’s ecosystem complexity and fragmentation issues, Codestaff builds and tests Android apps quickly. When it comes to experience, Codestaff has experience in Magento and creating eCommerce."
        }
    }
}

const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Android Developer",
    "image": "https://codestaff.io/android-developer.png",
    "description": "Freelance Android Developers on Code Staff are highly skilled mobile devs ready to develop and launch your Android App or APK in the Google Play Store. Hire a Remote Android Developer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/android-developer",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "David Alleva",
        "reviewBody": "",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "David Alleva" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}



const AndroidDeveloper = () => (
    <main>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <section className="cx_catagories">
            <div className="container-fluid cx_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-10">
                            <h2>
                                Hire top freelance<br /><span> Android Developers.</span>
                            </h2>
                            <p>
                                Codestaff is a marketplace for top Android Developers. Top
                                companies and start-ups choose Codestaff Android freelancers
                                for their mission critical software projects.
                            </p>
                            <Link to="/hire/" className="skills-banner-btn">
                                Hire a top Android Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <CxSearch title='HIRE FREELANCE ANDROID DEVELOPERS' banner='/android-developer.png' bannerAlt='android developer banner' />
            <HireAndApply />
            <TopTrendingSkills />
        </section>
    </main>
)

export default AndroidDeveloper
