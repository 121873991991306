import React from 'react'
import Layout from '../components/layout'
import AndroidDeveloper from '../components/categories/android-developer'
import Seo from '../components/seo'

const AndroidDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <AndroidDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Android Developer Freelancers | Codestaff'
  const description =
    'Hire the best Android Developer freelancers at Codestaff. Get the top 1% of Android Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default AndroidDeveloperPage
